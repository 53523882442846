import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton-product-box',
  templateUrl: './skeleton-product-box.component.html',
  styleUrls: ['./skeleton-product-box.component.scss']
})
export class SkeletonProductBoxComponent implements OnInit {

  @Input() gridscount: any;
  @Input() loader1: boolean = false;
  // loader1
  // @Input() gridscount: any;

  @Input() grids: any;
  grid: number = 12;
  value:number = 6
  constructor() {
    // this.grid = this.gridscount !== undefined && this.gridscount !== null ? this.gridscount.toString() : '';
    // console.log('grid:', this.grid);




  }

  ngOnInit(): void {

    const data = localStorage.getItem('myApp_gridcol');
    this.grid =  parseInt(data, 10) || 12;
    // console.log(this.grid)
    // console.log(this.value)
  }

}
