import { Component, OnInit } from '@angular/core';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';
import { MyService } from '../../services/myservice';
import { Router } from '@angular/router';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  public products: Product[] = [];
  public collapse: boolean = true;
  public Category: any[] = [];
  someCondition: Number = 0
  customerid: number
  constructor(public productService: ProductService, private myService: MyService, private router: Router,) {
    // this.productService.getProducts.subscribe(product => this.products = product);


    // const id = localStorage.getItem('myApp_catid');
    // this.customerid =  parseInt(id, 10);
    // console.log('myApp_catid', this.customerid);

    this.myService._catID.subscribe(data => {
      // console.log(data)
      this.someCondition = data
      // console.log(this.someCondition)

    })
    // console.log(this.someCondition)

    this.productService.Categories.subscribe(Cat => {
      const data = Cat["data"]
      this.Category = data
      // this.someCondition = this.Category[0].id
      if (this.someCondition == 0) {
        this.myService._catID.next(this.Category[0].id);
      }
      // this.someCondition = this.customerid || this.Category[0].id
      // console.log(this.someCondition, this.customerid, this.Category[0].id)
    });



  }

  catid: number = 0

  selectid(item) {
    // console.log(item)
    this.someCondition = item
    // this.router.navigateByUrl('/shop/collection/left/sidebar/' + item.id);

    this.myService._catID.next(item);
    // this.catid = item
    // localStorage.setItem("myApp_catid", JSON.stringify(this.someCondition));

  }

  ngOnInit(): void {
    this.myService._catID.subscribe(data => {
      // console.log(data)
      this.someCondition = data
      this.myService.PageNo.next(1);
    })
  }

  get filterbyCategory() {
    const category = [...new Set(this.Category.map(cat => cat.name))]
    return category
  }

  // get prodname(): string {
  //   return this.Category.description.replace(/\s+/g, '-')
  // }

  // CatIdLocal(){
  //   localStorage.setItem("CatId", JSON.stringify(state.cart));
  // }

}
