<div>
  <div class="img-wrapper" *ngIf="grid == 12 ">
    <!-- <ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', 'border-radius': '0', height: '160px' }"></ngx-skeleton-loader> -->
    <div class="wrapperload">
      <div class="loaderzig">
        <div class="loader__part loader__part--1"></div>
        <div class="loader__part loader__part--2"></div>
        <div class="loader__part loader__part--3"></div>
      </div>
    </div>


  </div>

  <div class="img-wrapper" *ngIf=" grid == 18">
    <!-- <ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', 'border-radius': '0', height: '160px' }"></ngx-skeleton-loader> -->
    <div class="wrapperload" style="height: 200px;">
      <div class="loaderzig">
        <div class="loader__part loader__part--1"></div>
        <div class="loader__part loader__part--2"></div>
        <div class="loader__part loader__part--3"></div>
      </div>
    </div>


  </div>

  <div class="img-wrapper" *ngIf="grid == 9">
    <div class="wrapperload9">
      <div class="loaderzig">
        <div class="loader__part loader__part--1"></div>
        <div class="loader__part loader__part--2"></div>
        <div class="loader__part loader__part--3"></div>
      </div>
    </div>

  </div>


  <div class="img-wrapper" *ngIf="grid == 6">
    <div class="wrapperload6">
      <div class="loaderzig">
        <div class="loader__part loader__part--1"></div>
        <div class="loader__part loader__part--2"></div>
        <div class="loader__part loader__part--3"></div>
      </div>
    </div>


  </div>


  <div class="product-detail">
    <h4 style="margin-top: 6px;">
      <ngx-skeleton-loader
        [theme]="{ 'background-color': '#e8e8e8', width: '130px', 'border-radius': '15px',  height: '15px', 'margin-bottom': '6px'}">
      </ngx-skeleton-loader>
    </h4>
    <h5>
      <ngx-skeleton-loader
        [theme]="{ 'background-color': '#e8e8e8', width: '160px', 'border-radius': '15px',  height: '12px'}">
      </ngx-skeleton-loader>
    </h5>
    <h6>
      <ngx-skeleton-loader
        [theme]="{ 'background-color': '#e8e8e8', width: '70px', 'border-radius': '15px',  height: '14px'}">
      </ngx-skeleton-loader>
    </h6>
  </div>
</div>



<div>
  <div class="img-wrapper" *ngIf="grids == 12 || grids == 18">
    <!-- <ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', 'border-radius': '0', height: '160px' }"></ngx-skeleton-loader> -->
    <div class="wrapperload" >
      <div class="loaderzig">
        <div class="loader__part loader__part--1"></div>
        <div class="loader__part loader__part--2"></div>
        <div class="loader__part loader__part--3"></div>
      </div>
    </div>


  </div>

  <div class="img-wrapper" *ngIf="grids == 9">
    <div class="wrapperload9">
      <div class="loaderzig">
        <div class="loader__part loader__part--1"></div>
        <div class="loader__part loader__part--2"></div>
        <div class="loader__part loader__part--3"></div>
      </div>
    </div>

  </div>


  <div class="img-wrapper" *ngIf="grids == 6">
    <div class="wrapperload6">
      <div class="loaderzig">
        <div class="loader__part loader__part--1"></div>
        <div class="loader__part loader__part--2"></div>
        <div class="loader__part loader__part--3"></div>
      </div>
    </div>


  </div>


  <!-- <div class="product-detail">
    <h4 style="margin-top: 6px;">
      <ngx-skeleton-loader
        [theme]="{ 'background-color': '#e8e8e8', width: '130px', 'border-radius': '15px',  height: '15px', 'margin-bottom': '6px'}">
      </ngx-skeleton-loader>
    </h4>
    <h5>
      <ngx-skeleton-loader
        [theme]="{ 'background-color': '#e8e8e8', width: '160px', 'border-radius': '15px',  height: '12px'}">
      </ngx-skeleton-loader>
    </h5>
    <h6>
      <ngx-skeleton-loader
        [theme]="{ 'background-color': '#e8e8e8', width: '70px', 'border-radius': '15px',  height: '14px'}">
      </ngx-skeleton-loader>
    </h6>
  </div> -->
</div>