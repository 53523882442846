<!--footer section -->
<footer [class]="class" *ngIf="isHeaderVisible">
	<div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>KNOW IT ALL FIRST!</h4>
								<p>Same Day Delivery is Available Across Chennai. </p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form"
							action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda"
							target="_blank" method="post">
							<div class="form-group mx-sm-3">
								<!-- <input type="email" name="email" class="form-control" id="email" maxlength="50"
									(input)="onInput($event)" placeholder="Enter your email"> -->
								<input type="text" name="email" class="form-control" id="email" maxlength="50"
									placeholder="Enter your Email" (input)="onInput($event)" [(ngModel)]="login.email"
									value="" autocomplete="off">

								<span
									*ngIf="login.email && (login.email.indexOf('&#64;') === -1 || login.email.indexOf('.') === -1)"
									class="text text-danger">
									Email must contain '&#64;' and '.'
								</span>
							</div>
							<button type="submit" style="max-height: 50px;" class="btn btn-solid"
								(click)="subscribe()">subscribe</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div>
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4>about</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]=" footer[0]?.logo|| footer1[0]?.logo" alt="logo">
						</div>
						<!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, </p> -->

						<p *ngIf="footer">{{footer[0]?.F1desc || footer1[0]?.F1desc}}</p>

						<div class="footer-social">
							<ul>
								<li>
									<a href="https://www.facebook.com/FBCakes/" target="_blank"><i
											class="fa fa-facebook" aria-hidden="true"></i></a>
								</li>
								<!-- <li>
									<a href="javascript:void(0)"><i class="fa fa-google-plus"
											aria-hidden="true"></i></a>
								</li> -->
								<!-- <li>
									<a href="javascript:void(0)"><i class="fa fa-twitter" aria-hidden="true"></i></a>
								</li> -->
								<li>
									<a href="https://www.instagram.com/fbcakesofficial/?hl=en" target="_blank"><i
											class="fa fa-instagram" aria-hidden="true"></i></a>
								</li>
								<!-- <li>
									<a href="javascript:void(0)"><i class="fa fa-rss" aria-hidden="true"></i></a>
								</li> -->
							</ul>
						</div>
					</div>
				</div>
				<!-- <div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title">
							<h4>Categories</h4>
						</div>
						<div class="footer-contant">
							<ul>
						
								<li *ngFor="let category of Categorysdata">
									<a (click)="selectid(category.id )" [style.color]="(category.id === someCondition) ? 'red' : '#777777'" 
										[routerLink]="['/shop/collection/left/sidebar']"
										[queryParams]="{ category: category.id + '-' + category.description }">
										{{ category.description }}
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div> -->
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
							<h4>Quick Links</h4>
						</div>
						<div class="footer-contant col-12 row">
							<ul class="col-6">
								<li><a [routerLink]="['/home/fashion']">Home</a></li>
								<li><a [routerLink]="['/pages/FAQ']" [queryParams]="{ name: 'FAQ'}">FAQ</a></li>
								<li><a [routerLink]="['/pages/Terms']" [queryParams]="{ name: 'Terms'}">Terms &
										Conditions</a></li>
								<li><a [routerLink]="['/pages/Refund']" [queryParams]="{ name: 'Refund'}">Refund
										Policy</a></li>
								<li><a [routerLink]="['/pages/Gallery']" [queryParams]="{ name: 'Gallery'}">Gallery</a>
								</li>
								<!-- <li><a href="javascript:void(0)">contacts</a></li> -->
							</ul>
							<ul class="col-6">
								<li><a [routerLink]="['/pages/aboutus']">About</a></li>
								<li><a [routerLink]="['/pages/StoreList']" [queryParams]="{ name: 'StoreList'}">Store
										List</a></li>
								<li><a [routerLink]="['/pages/PrivacyPolicy']"
										[queryParams]="{ name: 'PrivacyPolicy'}">Privacy Policy</a></li>
								<li><a [routerLink]="['/pages/DeliveryPolicy']"
										[queryParams]="{ name: 'DeliveryPolicy'}">Delivery Policy</a></li>
								<!-- <li><a href="javascript:void(0)">contacts</a></li> -->
							</ul>
						</div>
						<!-- <div class="footer-contant col-6">
							<ul>
								<li><a href="javascript:void(0)">shipping & return</a></li>
								<li><a href="javascript:void(0)">secure shopping</a></li>
								<li><a href="javascript:void(0)">gallary</a></li>
								<li><a href="javascript:void(0)">affiliates</a></li>
								<li><a href="javascript:void(0)">contacts</a></li>
							</ul>
						</div> -->
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
							<h4>store information</h4>
						</div>
						<div class="footer-contant">
							<ul class="contact-list">
								<!-- <li><i class="fa fa-map-marker"></i>Multikart Demo Store, Demo store
									India 345-659</li>
								<li><i class="fa fa-phone"></i>Call Us: 123-456-7898</li>
								<li><i class="fa fa-envelope-o"></i>Email Us: <a>Support&#64;Fiot.com</a></li> -->
								<!-- <li><i class="fa fa-fax"></i>Fax: 123456</li> -->
								<!-- <a href="https://maps.app.goo.gl/QWRNAw9oWEVPZjwD6"><i class="fa fa-map-marker"></i></a> -->

								<li> <i class="fa fa-map-marker"></i><a href="javascript:void(0)"
										(click)=" map()">{{footer[0]?.Lable2 ||
										footer1[0]?.Lable2}}</a>
								</li>
								<li><i class="fa fa-phone"></i><a href="javascript:void(0)"
										(click)="phone()">{{footer[0]?.Lable3 ||
										footer1[0]?.Lable3}}</a></li>

								<li> <i class="fa fa-envelope-o"></i>
									<a href="mailto:myemail@site.com?Subject=Some%20subject">{{footer[0]?.Lable4
										|| footer1[0]?.Lable4}}</a>
								</li>

							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
				<div class="col-xl-6 col-md-6 col-sm-12">
					<div class="footer-end">
						<!-- <p><i class="fa fa-copyright" aria-hidden="true"></i> 
						
						{{ today | date:'y'}} theme forest powered by pixelstrap
					
					</p> -->
					</div>
				</div>
				<div class="col-xl-6 col-md-6 col-sm-12">
					<div class="payment-card-bottom">
						<ul>
							<li>
								<a><img src="assets/images/icon/visa.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/mastercard.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/paypal.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/american-express.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/discover.png" alt=""></a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>
<!--footer section end -->



<ng-template #loginModel let-modal>
	<div class="modal-body">
		<div class="container-fluid p-0">
			<div class="row">
				<div class="modal-body">
					<strong style="font-weight: 700;">
						<h5>Your Not Logged In, Please Login to proceed to checkout!..</h5>
					</strong>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-solid" data-dismiss="modal"
						(click)="modal.dismiss('Cross click')">Close</button>
					<button type="button" class="btn btn-solid" (click)="logincheck()">Login</button>
				</div>
			</div>
		</div>
	</div>
</ng-template>