import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, startWith, delay, tap, catchError, retry } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { EcomProd, Product, HomepageModule, ordermodule, logo, products } from '../classes/product';
import { stat } from 'fs';
import { response } from 'express';

const state = {
  products: JSON.parse(localStorage['products'] || '[]'),
  wishlist: JSON.parse(localStorage['wishlistItems'] || '[]'),
  compare: JSON.parse(localStorage['compareItems'] || '[]'),
  cart: JSON.parse(localStorage['cartItems'] || '[]')
}

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  //Auth Url Import

  public Currency = { name: 'Dollar', currency: 'USD', price: 1 } // Default Currency
  public OpenCart: boolean = false;
  public Products
  public EcomProducts
  public Category
  public AllProd
  public HomeProducts
  public homepageprod
  public localcategory


  constructor(private http: HttpClient,
    private toastrService: ToastrService) {
    this.getcartdata()
    // localStorage.removeItem('myApp_Search');
    // this.getHomeFootHead()
    this.localcategory = this.Categories
    // console.log('cat',this.localcategory);

    // this.GetCategoryList.subscribe(categories => {
    //   this.localcategory = categories;
    //   console.log('local',this.localcategory);

    // });

  }

  /*
    ---------------------------------------------
    ---------------  Product  -------------------
    ---------------------------------------------
  */

  // Product
  private get products(): Observable<Product[]> {
    this.Products = this.http.get<Product[]>('assets/data/products.json').pipe(map(data => data));
    this.Products.subscribe(next => { localStorage['products'] = JSON.stringify(next) });
    return this.Products = this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));
  }


  // E Com Product
  // private get ecomproducts(): Observable<EcomProd[]> {
  //   const apiUrl = 'https://uat-ecom.azurewebsites.net/api/Home/getProductslist?catid=118';

  //   this.EcomProducts = this.http.get<EcomProd[]>(apiUrl).pipe(
  //     tap(data => localStorage.setItem('ecomproducts', JSON.stringify(data))),
  //     catchError(error => {
  //       console.error('Error fetching ecomproducts:', error);
  //       return of(JSON.parse(localStorage.getItem('ecomproducts') || '[]'));
  //     }),
  //     startWith(JSON.parse(localStorage.getItem('ecomproducts') || '[]'))
  //   );

  //   return this.EcomProducts;
  // }
  // This will log 112

  public CatId = localStorage.getItem('Catid');;
  public ParaCatId


  public value = 1
  public index = 0
  public l_price = 0
  public h_price = 2000
  public sid = 0
  public GetProds(CId, Value, Index, lprice, hprice, sid) {
    this.CatId = CId
    this.value = Value
    this.index = Index
    this.ParaCatId = this.CatId
    this.l_price = lprice
    this.h_price = hprice
    this.sid = sid
    return this.CatId
  }



  public ecomproducts(): Observable<EcomProd[]> {
    const apiUrl = 'https://uat-ecom.azurewebsites.net/api/Home/getProductslist?catid=' + this.CatId + '&value=' + this.value + '&index=' + this.index + '&l_price=' + this.l_price + '&h_price=' + this.h_price + '&sid=' + this.sid;
    this.EcomProducts = this.http.get<EcomProd[]>(apiUrl);
    // console.log(this.EcomProducts)

    return this.EcomProducts;
  }

  public ecomproddetails(id: number, cartid: number): Observable<EcomProd> {
    // console.log(id, 'mmmmmmmmm', cartid)
    const apiUrl = 'https://uat-ecom.azurewebsites.net/api/Home/getProductDetails?pid=' + id + '&cartid=' + cartid;
    // console.log(apiUrl)
    this.EcomProducts = this.http.get<EcomProd[]>(apiUrl);
    return this.EcomProducts;
  }

  public get Categories(): Observable<ProductService[]> {
    const apiUrl = 'https://uat-ecom.azurewebsites.net/api/Home/getCategoriesFB';

    // return this.http.get<EcomProd[]>(apiUrl);
    this.Category = this.http.get<EcomProd[]>(apiUrl);


    return this.Category;
  }

  public Allproducts(): Observable<products[]> {
    const apiUrl = 'https://uat-ecom.azurewebsites.net/api/Home/getallproductecom';
    this.AllProd = this.http.get<products[]>(apiUrl);
    return this.AllProd;
  }

  public get getAllProducts(): Observable<products[]> {
    return this.Allproducts();
  }


  // https://uat.fbcakes.com/shop/collection/left/sidebar?category=0

  // public homeecomproducts(): Observable<EcomProd[]> {
  //   const apiUrl = 'https://uat-ecom.azurewebsites.net/api/Home/getPremiumProducts?catid=0';
  //   this.HomeProducts = this.http.get<EcomProd[]>(apiUrl);
  //   console.log(this.HomeProducts['data'])
  //   return this.HomeProducts;
  // }

  public homePageproducts(): Observable<HomepageModule[]> {
    const apiUrl = 'https://uat-ecom.azurewebsites.net/api/Home/getHomeProducts?cid=12&tid=0';
    this.homepageprod = this.http.get<HomepageModule[]>(apiUrl);
    // this.logoLive = this.HomePage["logo"]
    // console.log(this.homepageprod)
    this.Homepagelogo = this.homepageprod
    return this.homepageprod;
  }

  public Homepagelogo

  //Get E Com Products

  public get GetEcomProducts(): Observable<EcomProd[]> {
    return this.ecomproducts();
  }

  // public get GetHomeProducts(): Observable<EcomProd[]> {
  //   return this.homeecomproducts();
  // }



  // Get Products
  public get getProducts(): Observable<Product[]> {
    return this.products;
  }

  public get GetCategoryList(): Observable<ProductService[]> {


    return this.Categories;


  }


  // https://uat-ecom.azurewebsites.net/api/Home/savetocart2

  public CartData

  public SaveCArt(body) {

    const apiUrl = `https://uat-ecom.azurewebsites.net/api/Home/savetocart2`;

    this.CartData = this.http.post(apiUrl, body);
    return this.CartData;
  }
  public LiveCartProd

  public LcartItem
  public LiveCart(): Observable<ordermodule[]> {
    const cusid = localStorage.getItem('customerid');
    const secid = localStorage.getItem('secid');
    const apiUrl = 'https://uat-ecom.azurewebsites.net/api/Home/getcart2?custid=' + cusid + '&secid=' + secid;
    this.LiveCartProd = this.http.get<products[]>(apiUrl);
    // console.log(apiUrl)

    // this.LiveCartProd.subscribe(response => this.LcartItem = response)

    return this.LiveCartProd;
    // this.LiveCartProd.subscribe(next => { localStorage['cartItems'] = JSON.stringify(next) });
    // return this.LiveCartProd = this.LiveCartProd.pipe(startWith(JSON.parse(localStorage['cartItems'] || '[]')));
  }

  public get getLiveCart(): Observable<ordermodule[]> {
    return this.LiveCart();
  }


  public CartAllData
  public get cartliveItems(): Observable<ordermodule[]> {
    this.getLiveCart.subscribe(data => {
      const datalive = data["cart"]
      // console.log(datalive)
      this.CartAllData = datalive
    })
    this.getcartdata()
    const itemsStream = new Observable(observer => {
      observer.next(this.CartAllData);
      observer.complete();
    });
    return <Observable<ordermodule[]>>itemsStream;
  }





  public get Homepageprod(): Observable<HomepageModule[]> {
    return this.homePageproducts();
  }

  // public foothead: any
  // getHomeFootHead() {
  //   this.homepageprod.subscribe(response => {
  //     // console.log(response)
  //     this.foothead = response
  //     console.log(this.foothead)
  //   })
  // }


  public HedFoot
  // public HedFootdata

  public getHedFoot(): Observable<HomepageModule[]> {
    const apiUrl = 'https://uat-ecom.azurewebsites.net/api/Home/getHomeProducts?cid=12&tid=1';
    this.HedFoot = this.http.get<HomepageModule[]>(apiUrl);
    // this.logoLive = this.HomePage["logo"]
    // console.log(this.HedFoot)
    // this.Homepagelogo = this.HedFoot
    return this.HedFoot;
  }

  public get HedFootdata(): Observable<HomepageModule[]> {
    return this.getHedFoot();
  }


  // https://uat-ecom.azurewebsites.net/api/Home/getHeaderEcom?cid=12
  public Header
  public getHeder(): Observable<HomepageModule[]> {
    const cusid = localStorage.getItem('customerid');
    const apiUrl = 'https://uat-ecom.azurewebsites.net/api/Home/getHeaderEcom?cid=12&custid=' + cusid;
    this.Header = this.http.get<HomepageModule[]>(apiUrl);
    return this.Header;
  }

  public get Headerget(): Observable<HomepageModule[]> {
    return this.getHeder();
  }


  public Footer
  public getFooter(): Observable<HomepageModule[]> {
    const apiUrl = 'https://uat-ecom.azurewebsites.net/api/Home/getFooterrEcom?cid=12';
    this.Footer = this.http.get<HomepageModule[]>(apiUrl);
    return this.Footer;
  }

  public get Footerget(): Observable<HomepageModule[]> {
    return this.getFooter();
  }

  HomePage = []
  public logoImg

  public logodata() {
    this.Homepageprod.subscribe(x => {
      this.HomePage = x
      // console.log(this.HomePage)
      this.HomePage = this.HomePage["logo"]
      // console.log(this.HomePage)
      const data = this.HomePage[0]?.imgurl
      // console.log(data)
    })

    // return  this.Homepageprod
  }

  public Ccount
  public CountCart
  getcartdata() {
    // this.Ccount = localStorage.getItem('myApp_CartCount');
    // this.CountCart = parseInt(this.Ccount, 10) || 0;
    // console.log(this.CountCart, 'pppppppppppppppppppppppppppppppp')
  }



  //   public get GETDELdata(): Observable<ProductService[]> {
  //     return this.CartDELData;
  // }

  // public logodata(): Observable<any> {
  //   return this.Homepagelogo.pipe(
  //     map(homePageData => {
  //       // Process homePageData as needed
  //       const logo = homePageData["logo"];
  //       const data = logo?.[0]?.imgurl;
  //       return data;
  //     })
  //   );
  // }



  // Get Products By Slug
  // public getProductBySlug(slug: string): Observable<Product> {
  //   return this.products.pipe(map(items => {
  //     return items.find((item: any) => {
  //       return item.title.replace(' ', '-') === slug;
  //     });
  //   }));
  // }

  public SaveData

  public SaveOrder(body) {

    const apiUrl = `https://uat-ecom.azurewebsites.net/api/Home/saveEcomOrder`;

    this.SaveData = this.http.post(apiUrl, body);
    return this.SaveData;
  }

  //Save orderBy Online

  public SaveDataonline

  public SaveOrderonline(body) {

    const apiUrl = `https://uat-ecom.azurewebsites.net/api/Home/saveEcomOrder_o`;

    this.SaveDataonline = this.http.post(apiUrl, body);
    return this.SaveDataonline;
  }


  /*
    ---------------------------------------------
    ---------------  Wish List  -----------------
    ---------------------------------------------
  */

  // Get Wishlist Items
  public get wishlistItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.wishlist);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Wishlist
  public addToWishlist(product): any {
    const wishlistItem = state.wishlist.find(item => item.id === product.id)
    if (!wishlistItem) {
      state.wishlist.push({
        ...product
      })
    }
    this.toastrService.success('Product has been added in wishlist.');
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    return true
  }

  // Remove Wishlist items
  public removeWishlistItem(product: Product): any {
    const index = state.wishlist.indexOf(product);
    state.wishlist.splice(index, 1);
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    return true
  }

  /*
    ---------------------------------------------
    -------------  Compare Product  -------------
    ---------------------------------------------
  */

  // Get Compare Items
  public get compareItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.compare);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Compare
  public addToCompare(product): any {
    const compareItem = state.compare.find(item => item.id === product.id)
    if (!compareItem) {
      state.compare.push({
        ...product
      })
    }
    this.toastrService.success('Product has been added in compare.');
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true
  }

  // Remove Compare items
  public removeCompareItem(product: Product): any {
    const index = state.compare.indexOf(product);
    state.compare.splice(index, 1);
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true
  }

  /*
    ---------------------------------------------
    -----------------  Cart  --------------------
    ---------------------------------------------
  */

  // Get Cart Items
  public get cartItems(): Observable<ordermodule[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.cart);
      observer.complete();
    });
    return <Observable<ordermodule[]>>itemsStream;
  }

  // Add to Cart
  public addToCart(product): any {
    // console.log(product)
    const cartItem = state.cart.find(item => item.product.id === product.product.id && item.product.message === product.product.message);
    // console.log(cartItem)
    const qty = product.quantity ? product.quantity : 1;
    const items = cartItem ? cartItem : product;
    const stock = this.calculateStockCounts(items, qty);

    if (!stock) return false

    if (cartItem) {
      cartItem.product.quantity += qty
    } else {
      state.cart.push({
        ...product,
        quantity: qty
      })
    }

    this.OpenCart = true; // If we use cart variation modal
    localStorage.setItem("cartItems", JSON.stringify(state.cart));
    return true;
  }

  // Update Cart Quantity
  // Function to update the quantity of a product in the shopping cart
  // Assuming ordermodule represents a product



  public updateCartQuantity(product: any, quantity: number): any | boolean {

    // console.log(product,'----------',quantity)
    const foundItem = state.cart.find((item: any, index: number) => {

      if (item.product && item.product.id === product.ProductId) {
        const newQuantity = item.product.quantity + quantity;

        const stockAvailable = this.calculateStockCounts(item, quantity);

        if (newQuantity !== 0 && stockAvailable) {
          item.product.quantity = newQuantity;
        }
        localStorage.setItem("cartItems", JSON.stringify(state.cart));
        return true;
      }
    });
    return foundItem || false;
  }









  // Calculate Stock Counts
  public calculateStockCounts(product, quantity) {
    const qty = product.quantity + quantity
    const stock = product.stock
    if (stock < qty || stock == 0) {
      this.toastrService.error('You can not add more items than available. In stock ' + stock + ' items.');
      return false
    }
    return true
  }

  // Remove Cart items
  public removeCartItem(product: ordermodule): any {
    const index = state.cart.indexOf(product);
    state.cart.splice(index, 1);
    localStorage.setItem("cartItems", JSON.stringify(state.cart));
    return true
  }

  // Total amount 
  public cartTotalAmount(): Observable<number> {

    return this.cartItems.pipe(
      map((items: any[]) => {
        return items.reduce((totalAmount, item) => {
          const price = item.weight.price;
          // console.log(price)

          const quantity = item.product.quantity;
          // console.log(quantity)

          const qty = (item.weight.qty * (item.options.price || 0)) + (item.weight.qty * (item.variants?.price || 0))

          // console.log(qty,item.weight.qty,item.options[0]?.price)

          return totalAmount + ((price + qty) * quantity);
        }, 0) * this.Currency.price;
      })
    );
  }








  /*
    ---------------------------------------------
    ------------  Filter Product  ---------------
    ---------------------------------------------
  */

  // Get Product Filter
  public filterProducts(filter: any): Observable<Product[]> {
    return this.products.pipe(map(product =>
      product.filter((item: Product) => {
        if (!filter.length) return true
        const Tags = filter.some((prev) => { // Match Tags
          if (item.tags) {
            if (item.tags.includes(prev)) {
              return prev
            }
          }
        })
        return Tags
      })
    ));
  }

  // Sorting Filter
  public sortProducts(products: Product[], payload: string): any {

    if (payload === 'ascending') {
      return products.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        } else if (a.id > b.id) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'a-z') {
      return products.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        } else if (a.title > b.title) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'z-a') {
      return products.sort((a, b) => {
        if (a.title > b.title) {
          return -1;
        } else if (a.title < b.title) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'low') {
      return products.sort((a, b) => {
        if (a.price < b.price) {
          return -1;
        } else if (a.price > b.price) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'high') {
      return products.sort((a, b) => {
        if (a.price > b.price) {
          return -1;
        } else if (a.price < b.price) {
          return 1;
        }
        return 0;
      })
    }
  }


  public sortEcomProducts(ecomproducts: EcomProd[], payload: string): any {
    // console.log(ecomproducts, '---', payload)

    let data = payload;
    data = data.replace(/"/g, '');
    // console.log(data)

    // localStorage.setItem("myApp_Sort", JSON.stringify(payload));

    if (data === 'ascending') {
      return ecomproducts.sort((a, b) => {
        if (a.pid < b.pid) {
          return -1;
        } else if (a.pid > b.pid) {
          return 1;
        }
        return 0;
      })
    } else if (data === 'a-z') {
      return ecomproducts.sort((a, b) => {
        if (a.pname < b.pname) {
          return -1;
        } else if (a.pname > b.pname) {
          return 1;
        }
        return 0;
      })
    } else if (data === 'z-a') {
      return ecomproducts.sort((a, b) => {
        if (a.pname > b.pname) {
          return -1;
        } else if (a.pname < b.pname) {
          return 1;
        }
        return 0;
      })
    } else if (data === 'low') {
      return ecomproducts.sort((a, b) => {
        if (a.pri < b.pri) {
          return -1;
        } else if (a.pri > b.pri) {
          return 1;
        }
        return 0;
      })
    } else if (data === 'high') {
      return ecomproducts.sort((a, b) => {
        if (a.pri > b.pri) {
          return -1;
        } else if (a.pri < b.pri) {
          return 1;
        }
        return 0;
      })
    }
  }



  /*
    ---------------------------------------------
    ------------- Product Pagination  -----------
    ---------------------------------------------
  */
  public getPager(totalItems: number, currentPage: number = 1, pageSize: number) {
    // console.log(totalItems, currentPage, pageSize)
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    let paginateRange = 3;

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage < paginateRange - 1) {
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

}
