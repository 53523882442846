import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { HomepageModule, Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';
import { ExternalService } from '../../services/external.service';
import { Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

  @Input() class: string = 'footer-light'; // Default class 
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() newsletter: boolean = true; // Default True

  public today: number = Date.now();
  public products: Product[] = [];
  public collapse: boolean = true;
  public Category: any[] = [];
  someCondition: any

  @Input() Categorysdata: any[] = [];

  @Input() footer: any = [];
  @ViewChild('loginModel', { static: true }) loginModel: ElementRef<any>

  public footer1 = []

  public fbcakelist = {
    message: 'Order Fresh Cream Cake online and get them Delivered Free. Serving in Bangalore, Chennai, Tiruvallur, Kancheepuram, Coimbatore, Madurai and Hyderabad.',
    address: 'HEAD OFFICE 60, RGM Complex, OMR, Karapakkam, Chennai-600097',
    phone: '90034 32888',
    email: 'Admin@Fbcakes.Com'
  };
  public HomePage: HomepageModule[] = [];

  login: any = {
    email: '',
  };
  denVal: any
  constructor(public productService: ProductService,
    private router: Router, private toastrService: ToastrService, private modalService: NgbModal, private externalService: ExternalService,



    private externalservice: ExternalService,) {

    const data = localStorage.getItem('denglu');
    this.denVal = parseInt(data, 10) || 0;

    // console.log(this.denVal)

    if (this.router.url.split('/')[1] === 'shop') {
      this.productService.Footerget.subscribe(x => {
        this.HomePage = x
        this.footer1 = this.HomePage["footer"]
        // console.log(this.footer)
        this.footer1.forEach((item) => {
          const itemName = JSON.parse(item.footer_content_1);
          // console.log(itemName)
          // this.logoLive = item.logo
          // const itemDesc = JSON.parse(item.desc);
          item.F1desc = itemName.desc
          item.F2title = itemName.title
          const itemName1 = JSON.parse(item.footer_content_2);
          item.Lable1 = itemName1.title
          item.Lable2 = itemName1.lable1
          item.Lable3 = itemName1.lable2
          item.Lable4 = itemName1.lable3
        });
        // console.log(this.footer)
      })

    }


    // this.productService.Categories.subscribe(Cat => {
    //   const data = Cat["data"]
    //   this.Category = data
    //   this.someCondition = this.Category[0].id
    //   // console.log(this.someCondition)
    // });


    // console.log(this.footer)


    // this.productService.HedFootdata.subscribe(x => {
    //   this.HomePage = x       

    //   this.footer1 = this.HomePage["header_footer"]      
    //   console.log(this.footer)
    //   this.footer1.forEach((item) => {
    //     const itemName = JSON.parse(item.footer_content_1);
    //     console.log(itemName)
    //     // this.logoLive = item.logo
    //     // const itemDesc = JSON.parse(item.desc);
    //     item.F1desc = itemName.desc
    //     item.F2title = itemName.title        
    //     const itemName1 = JSON.parse(item.footer_content_2); 
    //     item.Lable1 = itemName1.title
    //     item.Lable2 = itemName1.lable1
    //     item.Lable3 = itemName1.lable2
    //     item.Lable4 = itemName1.lable3
    //   });
    //   console.log(this.footer)
    // })

    // this.productService.Categories.subscribe(Cat => {
    //   const data = Cat["data"]
    //   this.Category = data
    //   this.someCondition = this.Category[0].id
    //   console.log(this.someCondition)
    // });
  }

  emailInputTouched = false;
  onInput(event: any) {
    this.emailInputTouched = true;
  }


  selectid(item) {
    this.someCondition = item

  }

  get isHeaderVisible(): boolean {
    return this.externalservice.getShowHeader();
  }

  ngOnInit(): void {
    if (this.router.url === '/pages/login') {
      this.externalservice.setShowHeader(false);
    } else {
      this.externalservice.setShowHeader(true);
    }
  }



  get filterbyCategory() {
    const category = [...new Set(this.Category.map(cat => cat.name))]
    return category
  }

  map() {
    window.open("https://tinyurl.com/ywup3twe", '_blank');
  }

  phone(): void {

    window.open('tel:+9003432888', '_self');
  }

  subscribe() {
    if (this.login.email.length > 0 && this.denVal != 0) {
      this.externalService.Subscribed('vijtest@gmail.com')
      this.externalService._getSubscribeMail.subscribe(response => {
        console.log(response)
      })
      this.toastrService.success('your Succesfully Subscribed!', '')
      this.login = {
        email: '',
      };
    } else if (this.denVal == 0) {
      this.modalService.open(this.loginModel, { centered: true, size: 'l' });
    }
    else {
      this.toastrService.warning('Email Empty!', '')
    }
  }


  logincheck() {
    this.modalService.dismissAll();
    this.router.navigate(['/pages/login'])
  }

}
