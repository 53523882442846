<!-- <h1>{{clearsearch}}</h1>
<div *ngIf="clearsearch > 0">
    <app-header-one [sticky]="true"></app-header-one>

</div> -->
<app-header-one ></app-header-one>
<router-outlet></router-outlet>
<app-footer-one class="hidevalue"></app-footer-one>


