import { Component, OnInit, Input, HostListener, viewChild, ViewChild } from '@angular/core';
import { HomepageModule } from '../../../shared/classes/product';
import { ProductService } from '../../../shared/services/product.service';
import { Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr';
import { FashionOneComponent } from 'src/app/home/fashion/fashion-one/fashion-one.component';
import { ExternalService } from '../../services/external.service';
import { OperatorFunction, Observable, distinctUntilChanged, map } from 'rxjs';
import { debounceTime } from 'rxjs';
import { response } from 'express';
import { MyService } from '../../services/myservice';


@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
  
})
export class HeaderOneComponent implements OnInit {
  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false

  @Input() Livelogo: string = 'https://biz1ps.azurewebsites.net/temp/audio/logo.png';

  @Input() footer: any = [];

  result: any[] = [];

  public stick: boolean = false;
  public collections = []
  public HomePage: HomepageModule[] = [];
  // public logoImg
  @Input() logoImg: string;

  public footer1 = []
  public CountCart
  public Ccount
  showHeader: boolean = true
  public AllSearchProd

  public header
  public Cdata: any = []
  public LiveCartCount
  public dataurl = '/shop/collection/left/sidebar'
  public clearsearch

  public strval1: number = 0
  constructor(
    public productService: ProductService,
    private router: Router,
    private toastrService: ToastrService,
    private externalservice: ExternalService,
    private myService: MyService
    // public FashionOneComponent: FashionOneComponent
  ) {

    // const headId = localStorage.getItem('Header_Content');
    // const integerValue = parseInt(headId);
    // this.HeadId = integerValue
    // console.log(this.HeadId)

    if (this.router.url == '/shop/accounts') {
      this.HeadId = 1
    }

    this.myService.HearderId.subscribe(data => {
      // console.log('Catttttttttttt',data)
      // this.catIdget = data
      this.HeadId = data
      // console.log(this.HeadId)

    })
    // const currentPath = this.router.url.split('/')[1];
    // console.log(currentPath);

    // if (currentPath === this.dataurl) {
    //   console.log('qwerty');
    // }

    const storedValue1 = localStorage.getItem('mypg');
    // console.log(storedValue1)
    // this.strval1 = parseInt(storedValue1);


    if (localStorage.getItem('denglu') === '1') {
      this.Ccount = localStorage.getItem('myApp_CartCount');
      this.CountCart = parseInt(this.Ccount, 10) || 0;
      // console.log(this.CountCart)
    } else {
      this.CountCart = 0
    }

    // if (this.strval1 > 0) {
    if (this.router.url.split('/')[1] === 'shop') {
      // console.log(this.router.url.split('/')[1],'shop')
      this.productService.Headerget.subscribe(response => {
        // console.log(response)
        const head = response["header"]
        // console.log(head)
        this.header = head[0]?.logo
        this.Cdata = response['cccount']
        // console.log(this.Cdata)
        this.LiveCartCount = this.Cdata[0]?.cart_Count
        // localStorage.setItem("myApp_CartCount", JSON.stringify(this.LiveCartCount));
        // console.log(this.LiveCartCount)

      })

    }



    // localStorage.removeItem('myApp_Search');


    if (localStorage.getItem('denglu') === '1') {
      this.Ccount = localStorage.getItem('myApp_CartCount');
      this.CountCart = parseInt(this.Ccount, 10) || 0;
      // console.log(this.CountCart)
    } else {
      this.CountCart = 0
    }


    const storedValue = localStorage.getItem('mypg');

    this.clearsearch = parseInt(storedValue, 10) || 0
    // console.log(this.clearsearch)
    // if (this.clearsearch == 0) {


    // console.log(this.CountCart)

    // this.Cdata = this.productService.getcartdata()



    // this.productService.HedFootdata.subscribe(x => {
    //   this.HomePage = x       

    //   this.footer1 = this.HomePage["header_footer"]      
    //   console.log(this.footer)
    //   this.footer1.forEach((item) => {
    //     const itemName = JSON.parse(item.footer_content_1);
    //     console.log(itemName)
    //     // this.logoLive = item.logo
    //     // const itemDesc = JSON.parse(item.desc);
    //     item.F1desc = itemName.desc
    //     item.F2title = itemName.title        
    //     const itemName1 = JSON.parse(item.footer_content_2); 
    //     item.Lable1 = itemName1.title
    //     item.Lable2 = itemName1.lable1
    //     item.Lable3 = itemName1.lable2
    //     item.Lable4 = itemName1.lable3
    //   });
    //   console.log(this.footer)
    // })

    // this.productService.Homepageprod.subscribe(x => {
    //   this.HomePage = x
    //   // console.log(this.HomePage)
    //   const logo = this.HomePage["logo"]
    //   // console.log(logo)
    //   this.logoImg = logo[0].imgurl
    //   console.log(this.logoImg)
    // })

    // this.logoImg = this.route.snapshot.params['imgUrl']
    // console.log(this.logoImg)

    // this.FashionOneComponent.logoImg = this.route.snapshot.params['logoImg']
    // console.log(this.FashionOneComponent.logoImg)

    this.productService.getAllProducts.subscribe(Cat => {
      const data = Cat["products"]
      this.AllSearchProd = data
      // console.log(this.AllSearchProd)
    });

    const data = localStorage.getItem('myApp_Search');
    // console.log(data);
    let data1 = data || null;

    if (data1 !== null) {
      data1 = data.replace(/"/g, '');
    }

    this.model = data1 || '';
    // console.log(this.model)
  }
  model: string = null;

  // ngOnDestroy() {
  //   this.logoImg.unsubscribe();
  // }

  search: OperatorFunction<string, readonly { name; imgUrl }[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map((term) =>
        term === ''
          ? []
          : this.AllSearchProd.filter((v) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10),
        // console.log(this.AllSearchProd)
      ),
    );

  formatter = (x: { name: string }) => x.name || this.model;


  clearchat(){
    this.myService.ChatId.next(0);
    this.closehover = true
  }

  closehover:boolean = false

  loginheaderhide() {
    
    this.externalservice.getShowHeader()
    this.externalservice.setShowHeader(false);
    this.router.navigate(['/pages/login']);
    this.externalservice.getShowHeader()
  }

  get isHeaderVisible(): boolean {
    return this.externalservice.getShowHeader();
  }


  selectedItem(item) {
    // console.log(item)
    // console.log(this.model)
    // console.log(this.model)
    // this.router.navigateByUrl('/shop/collection/left/sidebar?ProdId=' + item.id )
    this.router.navigateByUrl('/shop/product/left/sidebar/' + item.id + '/' + item.name);
    localStorage.setItem("myApp_Search", JSON.stringify(item.name));
    // this.model = item.name
    // console.log(this.model)
    // this.formatter = null

    // setTimeout(() => {
    //   console.log('50000000000000000')
    //   this.formatter = (x: { name: string }) => x.name = null;
    //   console.log(this.formatter)
    // }, 8000);

    // item = null
    // console.log(item)
  }


  // navigateToLogin() {
  //   // Hide the header
  //   this.externalservice.setShowHeader(false);

  //   // Navigate to the login page
  //   this.router.navigate(['/pages/login']);
  // }

  // // Function to navigate to the home page
  // navigateToHome() {
  //   // Show the header
  //   this.externalservice.setShowHeader(true);

  //   // Navigate to the home page
  //   this.router.navigate(['/home']);
  // }

  // constructor(private router: Router, private toastrService: ToastrService) { }
  name = "";


  HeadId: number = 0

  ngOnInit(): void {

    // const headId = localStorage.getItem('Header_Content');
    // const integerValue = parseInt(headId);
    // this.HeadId = integerValue
    // console.log(this.HeadId)

  

    this.myService.HearderId.subscribe(data => {
      // console.log('Catttttttttttt',data)
      // this.catIdget = data
      this.HeadId = data
      // console.log(this.HeadId)

    })
    if (this.router.url == '/shop/accounts') {
      this.HeadId = 1
    }

    this.myService._pageId.subscribe(data => {
      // console.log(data)
      this.strval1 = data
      // console.log(this.strval1)

    })

    if (localStorage.getItem('name')) {
      this.name = localStorage.getItem('name');
    }


    if (this.router.url === '/pages/login') {
      this.externalservice.setShowHeader(false);
    } else {
      this.externalservice.setShowHeader(true);
    }

    // if (this.router.url === 'Home/fashion') {
    //   console.log(this.router.url)
    //   this.model = null     
    // }

    // window.location.reload()

  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 150 && window.innerWidth > 400) {
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

  localStorage: Storage = window.localStorage;

  denglu = 0

  logout() {
    localStorage.removeItem('customerid');
    localStorage.removeItem('denglu');

    localStorage.removeItem('productId');
    localStorage.removeItem('prdname');
    localStorage.removeItem('myApp_CartCount');
    localStorage.removeItem('name');
    localStorage.removeItem('Chat');
    localStorage.removeItem('fav');
    // localStorage.removeItem('myApp_CartCount');



    // console.log('REMOVE', localStorage.removeItem('customerid'));

    localStorage.setItem('mypg', '5');
    this.myService._pageId.next(5);
    this.router.navigateByUrl('home/fashion')

    if (this.router.url == 'home/fashion') {
      location.reload()
    }

    // console.log('denglu');

    if (localStorage.getItem('denglu')) {
      this.toastrService.success('Login', ' Login Successfully!')
    } else {
      this.toastrService.success('LogOut', ' LogOut Successfully!')
    }
  }

  yourOrders(item) {
    this.router.navigate(['/shop/order/management/']);
    // localStorage.setItem("Header_Content", JSON.stringify(item));
    this.myService.HearderId.next(item);
    this.closehover = false
  }

  yourAccount(item) {
    this.router.navigate(['/shop/accounts/']);
    // localStorage.setItem("Header_Content", JSON.stringify(item));
    this.myService.HearderId.next(item);
    this.closehover = false
  }
  yourwishlist(item) {
    this.router.navigate(['/shop/wishlist/']);
    // localStorage.setItem("Header_Content", JSON.stringify(item));
    this.myService.HearderId.next(item);
    this.closehover = false
  }


  isActive(result): boolean {
    // Add your logic to determine if the result is active
    return result.name === 'Result 1'; // Example condition
  }
}
