import { Component, OnInit, Injectable, PLATFORM_ID, Inject, ViewChild, ElementRef, input, Input } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from "../../services/product.service";
import { Product, EcomProd, ordermodule } from "../../classes/product";
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { response } from 'express';
import { MyService } from '../../services/myservice';
import { Console } from 'console';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  @ViewChild('loginModel', { static: true }) loginModel: ElementRef<any>
  public products: Product[] = [];
  public ecomproduct: ordermodule[] = [];
  public search: boolean = false;

  public ecomproducts: ordermodule[] = [];

  @Input() CountCart: number;

  public languages = [{
    name: 'English',
    code: 'en'
  }, {
    name: 'French',
    code: 'fr'
  }];

  public currencies = [{
    name: 'Euro',
    currency: 'EUR',
    price: 0.90 // price of euro
  }, {
    name: 'Rupees',
    currency: 'INR',
    price: 70.93 // price of inr
  }, {
    name: 'Pound',
    currency: 'GBP',
    price: 0.78 // price of euro
  }, {
    name: 'Dollar',
    currency: 'USD',
    price: 1 // price of usd
  }]

  public lenData: any = []

  public Cdata

  public Ccount
  public LivCnt

  public c_count: Number = 0

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private router: Router, private modalService: NgbModal,
    private translate: TranslateService,
    public productService: ProductService, private myService: MyService) {
    // console.log('carttttttttttttcountttttttttttttttttttttttttttt')

    this.Cdata = this.productService.getcartdata()
    // console.log(this.Cdata)
    // this.productService.cartliveItems.subscribe(response => {
    //   console.log(response)
    //   this.ecomproducts = response
    //   console.log(this.ecomproducts)
    // });

    // this.productService.getLiveCart.subscribe(response => {
    //   this.lenData = response['cart']
    //   // console.log(this.lenData)
    //   // console.log('-------22222===========44444444444444-0-0-0-0-0-0-0-0-0-0-0-055')
    // });
    // console.log(this.ecomproducts)

    if (localStorage.getItem('denglu') === '1') {
      this.Ccount = localStorage.getItem('myApp_CartCount');
      this.LivCnt = parseInt(this.Ccount, 10) || 0;
      this.c_count = parseInt(this.Ccount, 10) || 0;
      // console.log("VV LivCnt 1", this.LivCnt)
      // console.log("dd LivCnt 1", this.c_count)
    } else {
      this.LivCnt = 0
      this.c_count = 0;
      // console.log("VV LivCnt 2", this.LivCnt)
      // console.log("DD LivCnt 2", this.c_count)
    }


  }
  loadgrid: Number = 0
  ngOnInit(): void {
    this.myService.cartsource.subscribe(data => {
      // console.log(data)
      this.c_count = data
      // console.log("this.c_count: ", this.c_count)
    })

    this.getcartitemquantity();
    if (localStorage.getItem('denglu') === '1') {
      this.Ccount = localStorage.getItem('myApp_CartCount');
      this.LivCnt = parseInt(this.Ccount, 10) || 0;
      this.c_count = parseInt(this.Ccount, 10) || 0;
      // console.log("VVV LivCnt 1", this.LivCnt)
    } else {
      this.LivCnt = 0;
      this.c_count = 0;
      // console.log("VVV LivCnt 2", this.LivCnt)
    }


    // this.myService.messageSource.subscribe(data => {
    //   console.log(data)
    //   this.testData = data
    // })


  }

  getcartitemquantity() {
    var count = 0;
    if (JSON.parse(localStorage.getItem('cartItems'))) {
      count = JSON.parse(localStorage.getItem('cartItems')).length
    }
    var count2 = localStorage.getItem('myApp_CartCount');
    // console.log("C Count: ", count2);
    return count2;
  }

  // get prodname(): string {
  //   return this.ecomproduct.pname.replace(/\s+/g, '-')
  // }

  searchToggle() {
    this.search = !this.search;
  }


  getcart() {
    // [routerLink]="['/shop/cart']"
    if (localStorage.getItem('denglu') === '1') {
      this.router.navigate(['/shop/cart']);
    } else {
      this.modalService.open(this.loginModel, { centered: true, size: 'lg' });
    }

  }


  getOrder() {
    // [routerLink]="['/shop/cart']"
    if (localStorage.getItem('denglu') === '1') {
      this.router.navigate(['/shop/order/management/']);
    } else {
      this.modalService.open(this.loginModel, { centered: true, size: 'lg' });
    }

  }

  Home() {

    this.router.navigate(['home/fashion']);
    this.myService.ChatId.next(0);
  }

  Wishlist() {
    this.router.navigate(['/shop/wishlist']);
    this.myService.ChatId.next(0);
  }

  logincheck() {
    this.modalService.dismissAll();
    this.router.navigate(['/pages/login'])
    this.myService.ChatId.next(0);
  }


  changeLanguage(code) {
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code)
    }
  }

  get getTotal(): Observable<number> {
    return
    this.productService.cartTotalAmount();
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency
  }
  chathide: boolean = false




  Chat() {
    // console.log('chat----work')
    // this.router.navigate(['/shop/chat'])
    this.myService.ChatId.next(1);
  }

  send() {

  }

  closechat() {
    this.chathide = false

  }
}
