import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { EcomProd, Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-product-box-one',
  templateUrl: './product-box-one.component.html',
  styleUrls: ['./product-box-one.component.scss']
})
export class ProductBoxOneComponent implements OnInit {

  @Input() product: Product;
  @Input() ecomproduct: EcomProd;
  @Input() currency: any = this.productService.Currency; // Default Currency 
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;
  @Input() grids: any;
  
  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  public ImageSrc: string

  constructor(private productService: ProductService,private toastrService: ToastrService) {
    // console.log(this.ecomproduct)

    // console.log(this.grids)
  }

  ngOnInit(): void {
    if (this.loader) {
      setTimeout(() => { this.loader = false; }, 1000); // Skeleton Loader
    }
  }
  get prodname(): string {
    return this.ecomproduct?.pname.replace(/\s+/g, '-')
  }
  // Get Product Color
  // Color(variants) {
  //   const uniqColor = [];
  //   for (let i = 0; i < Object.keys(variants).length; i++) {
  //     if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
  //       uniqColor.push(variants[i].color)
  //     }
  //   }
  //   return uniqColor
  // }

  // Change Variants
  ChangeVariants(color, product) {
    product.variants.map((item) => {
      if (item.color === color) {
        product.images.map((img) => {
          if (img.image_id === item.image_id) {
            this.ImageSrc = img.src;
          }
        })
      }
    })
  }

  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }

  addToCart(product: any) {
    // this.productService.addToCart(product);
  }

  addToWishlist2(product: any) { 
    this.productService.addToWishlist(product);
  }

  addToWishlist(item: any) { 
    if(localStorage.getItem("fav"))
  {
    var odata=JSON.parse(localStorage.getItem("fav"))
    // console.log("odata::",odata);
    var isPresent = odata.some(function(el){ return el.pid === item.pid});
    if(isPresent)
    {
      this.toastrService.warning('Item Already Saved', item.pname)
    }
    else
    {
      odata.push(item)
      localStorage.setItem("fav",JSON.stringify(odata));
      this.toastrService.success('Saved Favourite', item.pname)
    }
  }
  else
  {
    localStorage.setItem("fav",JSON.stringify([item]));
    this.toastrService.success('Saved Favourite', item.pname)
  }
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }

}
